<template>
  <component :is="as" :class="$cn('relative px-8 md:px-10', styles.size, ui?.base)">
    <slot name="background">
      <slot name="background-overlay">
        <div v-if="backgroundOverlay" :class="$cn({ 'absolute inset-0 bg-gray-900': backgroundOverlay === 'dark' }, ui?.backgroundOverlay)" />
      </slot>
      <slot name="background-image">
        <div v-if="backgroundImage" :class="$cn('absolute inset-0', styles.backgroundOpacity, ui?.backgroundImage)">
          <img :src="backgroundImage" class="h-full w-full object-cover" />
        </div>
      </slot>
    </slot>
    <Container :no-center="noCenter" :size="container" :ui="{ base: $cn('relative', styles.container, ui?.container) }">
      <slot />
    </Container>
  </component>
</template>

<script setup lang="ts">
import type { ContainerSize } from "./Container.vue";
import type { ClassValue } from "clsx";

const props = withDefaults(
  defineProps<{
    as?: "header" | "footer" | "main" | "section" | "div";
    backgroundImage?: string;
    backgroundOverlay?: "dark" | "light";
    backgroundOpacity?: 0 | 5 | 10 | 20 | 25 | 30 | 40 | 50 | 60 | 70 | 75 | 80 | 90 | 95;
    container?: ContainerSize;
    noCenter?: true;
    spaced?: boolean;
    size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl";
    ui?: Partial<{
      base: ClassValue;
      backgroundImage: ClassValue;
      backgroundOverlay: ClassValue;
      container: ClassValue;
    }>;
  }>(),
  {
    as: "section",
    size: "xl",
  }
);

const styles = {
  backgroundOpacity: {
    "opacity-0": props.backgroundOpacity === 0,
    "opacity-5": props.backgroundOpacity === 5,
    "opacity-10": props.backgroundOpacity === 10,
    "opacity-20": props.backgroundOpacity === 20,
    "opacity-25": props.backgroundOpacity === 25,
    "opacity-30": props.backgroundOpacity === 30,
    "opacity-40": props.backgroundOpacity === 40,
    "opacity-50": props.backgroundOpacity === 50,
    "opacity-60": props.backgroundOpacity === 60,
    "opacity-70": props.backgroundOpacity === 70,
    "opacity-75": props.backgroundOpacity === 75,
    "opacity-80": props.backgroundOpacity === 80,
    "opacity-90": props.backgroundOpacity === 90,
    "opacity-95": props.backgroundOpacity === 95,
  },
  container: {
    "space-y-4 md:space-y-6 lg:space-y-8": props.spaced && props.size === "xxs",
    "space-y-6 md:space-y-8 lg:space-y-10": props.spaced && props.size === "xs",
    "space-y-8 md:space-y-12 lg:space-y-16": props.spaced && props.size === "sm",
    "space-y-12 md:space-y-16 lg:space-y-20": props.spaced && props.size === "md",
    "space-y-16 md:space-y-20 lg:space-y-24": props.spaced && props.size === "lg",
    "space-y-20 md:space-y-24 lg:space-y-28": props.spaced && props.size === "xl",
    "space-y-24 md:space-y-28 lg:space-y-32": props.spaced && props.size === "2xl",
    "space-y-28 md:space-y-32 lg:space-y-36": props.spaced && props.size === "3xl",
    "space-y-32 md:space-y-36 lg:space-y-40": props.spaced && props.size === "4xl",
    "space-y-36 md:space-y-40 lg:space-y-44": props.spaced && props.size === "5xl",
  },
  size: {
    "py-4 md:py-6 lg:py-8": props.size === "xxs",
    "py-6 md:py-8 lg:py-10": props.size === "xs",
    "py-8 md:py-12 lg:py-16": props.size === "sm",
    "py-12 md:py-16 lg:py-20": props.size === "md",
    "py-16 md:py-20 lg:py-24": props.size === "lg",
    "py-20 md:py-24 lg:py-28": props.size === "xl",
    "py-24 md:py-28 lg:py-32": props.size === "2xl",
    "py-28 md:py-32 lg:py-36": props.size === "3xl",
    "py-32 md:py-36 lg:py-40": props.size === "4xl",
    "py-36 md:py-40 lg:py-44": props.size === "5xl",
  },
};
</script>
